import { ComponentClass } from "react"
import { Dictionary, LayoutProps } from "@tm/morpheus"
import DashboardLayout from "./dashboard"
import DashboardLayoutOptionThree from "./dashboard-option-three"
import DashboardLayoutOptionTwo from "./dashboard-option-two"
import MultiwidgetLayout from "./multiwidget"
import OneSlotLayout from "./one-slot"
import SidebarLayout from "./sidebar"
import ThreeSlotLayout from "./three-slot"
import TwoSlotLayout from "./two-slot"

export const layouts: Dictionary<ComponentClass<LayoutProps>> = {
    "one-slot": OneSlotLayout,
    "two-slot": TwoSlotLayout,
    "three-slot": ThreeSlotLayout,
    "dashboard": DashboardLayout,
    "dashboard-option-two": DashboardLayoutOptionTwo as any,
    "dashboard-option-three": DashboardLayoutOptionThree as any,
    "multiwidget": MultiwidgetLayout,
    "sidebar": SidebarLayout,
}
